import { IIconSet } from "@hopper-b2b/utilities";
import { Brand } from "@b2bportal/lloyds-auth-api";
import { IconNameEnum, IconSet } from "@b2bportal/core-themes";

import BasicEconomyBunny from "./client/shared/fareClasses/bunny-basic-economy.png";
import BusinessBunny from "./client/shared/fareClasses/bunny-business.png";
import EconomyBunny from "./client/shared/fareClasses/bunny-economy.png";
import FirstClassBunny from "./client/shared/fareClasses/bunny-first-class.png";
import PremiumEconomyBunny from "./client/shared/fareClasses/bunny-premium-economy.png";
import List from "./client/shared/list.svg";
import Map from "./client/shared/map.svg";
import CarouselArrowLeft from "./client/shared/carousel-arrow-left.svg";
import CarouselArrowRight from "./client/shared/carousel-arrow-right.svg";

import {
  Icons,
  ChevronLeft,
  ChevronDown,
  ChevronRight,
  CheckFilled,
  Purchase,
  Filters,
  Close,
  Info,
  Airplane,
  Calendar,
  Plus,
  Check,
  Warning,
  DiagonalPlane,
  DisruptionProtection,
  GenericDetail,
  CancelForAnyReason,
  Informational,
  ClockFace,
  InformationalBrand,
  Dollar,
  TenantLogo,
  BritishPound,
  Credits,
  Coins,
} from "./client/brandIcons";

export { Close, Coins };
export { DiagonalPlane } from "./client/brandIcons";
export { Hotel } from "./client/brandIcons";
export { Conversation } from "./client/brandIcons";
export { CheckmarkSuccess } from "./client/brandIcons";
export { CheckFilled } from "./client/brandIcons";
export { Traveller } from "./client/brandIcons";

export const getBrandIcons = (brand: Brand): IIconSet => ({
  avatar: Icons.avatar[brand],
  airplane: Icons.airplane[brand],
  airplaneArrive: Icons.airplaneArrive[brand],
  airplaneDepart: Icons.airplaneDepart[brand],
  airplaneFintech: Icons.airplaneFintech[brand],
  bell: Icons.bell[brand],
  blackLock: Icons.blackLock[brand],
  calendar: Icons.calendar[brand],
  calendarStartIcon: Icons.calendarStartIcon[brand],
  calendarEndIcon: Icons.calendarEndIcon[brand],
  card: Icons.card[brand],
  carrotCoin: Icons.carrotCoin[brand],
  checkmarkFilled: Icons.checkmarkFilled[brand],
  checkmarkSimple: Icons.checkmarkSimple[brand],
  chevron: Icons.chevron[brand],
  chevronLeft: Icons.chevronLeft[brand],
  chevronDown: Icons.chevronDown[brand],
  clock: Icons.clock[brand],
  error: Icons.error[brand],
  filter: Icons.filter[brand],
  flights: Icons.flights[brand],
  guest: Icons.guest[brand],
  hotels: Icons.hotels[brand],
  info: Icons.info[brand],
  locationMarker: Icons.locationMarker[brand],
  lock: Icons.lock[brand],
  missedConnection: Icons.missedConnection[brand],
  oneway: Icons.oneway[brand],
  passenger: Icons.passenger[brand],
  priceFreeze: Icons.priceFreeze[brand],
  roundtrip: Icons.roundtrip[brand],
  shield: Icons.shield[brand],
  wallet: Icons.wallet[brand],
  walletIcon: Icons.walletIcon[brand],
  warning: Icons.warning[brand],
  logoSmall: Icons.logoSmall[brand],
  fareRatings: {
    0: BasicEconomyBunny,
    1: EconomyBunny,
    2: PremiumEconomyBunny,
    3: BusinessBunny,
    4: FirstClassBunny,
  },
  star: Icons.star[brand],
  bunnyWithSuitcase: Icons.bunnyWithSuitcase[brand],
  bed: Icons.bed[brand],
  close: Icons.close[brand],
  grayGuest: Icons.grayGuest[brand],
  grayShieldX: Icons.grayShieldX[brand],
  infoWhite: Icons.infoWhite[brand],
  listIcon: List,
  mapIcon: Map,
  greenShieldCheck: Icons.greenShieldCheck[brand],
  copy: Icons.copy[brand],
  cfar: Icons.cfar[brand],
  cfarWhite: Icons.cfarWhite[brand],
  check: Icons.check[brand],
  flightDelay: Icons.flightDelay[brand],
  infoQuestion: Icons.infoQuestion[brand],
  vipIcon: Icons.vipIcon[brand],
  headset: Icons.headset[brand],
  radio: Icons.radio[brand],
  radioSelected: Icons.radioSelected[brand],
  logoWhite: Icons.logoWhite[brand],
  backWhite: Icons.backWhite[brand],
  carouselArrowLeft: CarouselArrowLeft,
  carouselArrowRight: CarouselArrowRight,
  purchase: Icons.purchase[brand],
  unavailable: Icons.unavailable[brand],
  priceDropProtection: Icons.priceDropProtection[brand],
  switch: Icons.switch[brand],
});

export const defaultLloydsIcons: IconSet = {
  [IconNameEnum.info]: Info,
  [IconNameEnum.dropdownArrow]: ChevronDown,
  [IconNameEnum.backIcon]: ChevronLeft,
  [IconNameEnum.actionIcon]: ChevronRight,
  [IconNameEnum.filter]: Filters,
  [IconNameEnum.close]: Close,
  [IconNameEnum.nextIcon]: ChevronRight,
  [IconNameEnum.airplane]: Airplane,
  [IconNameEnum.calendar]: Calendar,
  [IconNameEnum.plus]: Plus,
  [IconNameEnum.check]: Check,
  [IconNameEnum.checkFilled]: CheckFilled,
  [IconNameEnum.airplaneDiagonal]: DiagonalPlane,
  [IconNameEnum.fareDetailsAvailable]: CheckFilled,
  [IconNameEnum.fareDetailsCharge]: Purchase,
  [IconNameEnum.fareDetailsUnavailable]: GenericDetail,
  [IconNameEnum.fareDetailsGeneric]: GenericDetail,
  [IconNameEnum.chevronRight]: ChevronRight,
  [IconNameEnum.warning]: Warning,
  [IconNameEnum.disruptionProtection]: DisruptionProtection,
  [IconNameEnum.disruptionHowItWorksDialogInfoLineOne]: InformationalBrand,
  [IconNameEnum.disruptionHowItWorksDialogInfoLineTwo]: Airplane,
  [IconNameEnum.cancelForAnyReason]: CancelForAnyReason,
  [IconNameEnum.informational]: Informational,
  [IconNameEnum.clockFace]: ClockFace,
  [IconNameEnum.informationalBrand]: InformationalBrand,
  [IconNameEnum.dollar]: Dollar,
  [IconNameEnum.tenantLogo]: TenantLogo,
  [IconNameEnum.currency]: BritishPound,
  [IconNameEnum.credits]: Credits,
  [IconNameEnum.coins]: Coins,
  [IconNameEnum.importantInformation1]: ClockFace,
  [IconNameEnum.importantInformation2]: BritishPound,
  [IconNameEnum.importantInformation3]: Info,
  [IconNameEnum.checkBulletPoint]: Check,
};
