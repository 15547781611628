import {
  IPassengerCounts,
  ITripTerminus,
  TripCategory,
} from "@b2bportal/core-types";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FloatingBox } from "@hopper-b2b/ui";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { PassengerPickerInput } from "@lloyds/ui-connected";
import { ActionButton } from "@lloyds/ui-core";
import { Grid } from "@material-ui/core";
import { FlightLocationAutoComplete } from "../FlightLocationAutocomplete";
import "./styles.scss";
import { TripCategoryPicker } from "./FlightCategoryPicker";
import {
  suggestionToITripTerminus,
  terminusToSuggestion,
} from "@b2bportal/core-utilities";
import { useRef } from "react";

export type FlightSearchDestinationProps = {
  initialOrigin?: ITripTerminus;
  initialDestination?: ITripTerminus;
  origin?: ITripTerminus;
  destination?: ITripTerminus;
  setOrigin: (origin: ITripTerminus) => void;
  setDestination: (dest: ITripTerminus) => void;
  passengerCount: IPassengerCounts;
  setPassengerCount: (paxCounts: IPassengerCounts) => void;
  tripCategory: TripCategory;
  setTripCategory: (tripCat: TripCategory) => void;
  onOpen?: () => void;
  onClick: () => void;
};

export const FlightSearchDestination = ({
  origin,
  destination,
  setOrigin,
  setDestination,
  passengerCount,
  setPassengerCount,
  tripCategory,
  setTripCategory,
  onOpen,
  onClick,
}: FlightSearchDestinationProps) => {
  const { t } = useI18nContext();
  const icons = useTenantIcons();

  const originRef = useRef(null);
  const destinationRef = useRef(null);

  return (
    <>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={6} sm={3} lg={3}>
          <PassengerPickerInput
            paxCounts={passengerCount}
            updatePaxCounts={setPassengerCount}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <TripCategoryPicker
            tripCategory={tripCategory}
            setTripCategory={setTripCategory}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="stretch"
        style={{
          marginTop: 6,
        }}
      >
        <Grid item xs={12} lg={3}>
          <FlightLocationAutoComplete
            id="origin"
            label=""
            placeholder={t("searchControl.whereFrom")}
            onChange={(sug) => sug && setOrigin(suggestionToITripTerminus(sug))}
            onOpen={onOpen}
            defaultValue={origin && terminusToSuggestion(origin)}
            icon={<img src={icons.locationMarker} alt="" />}
            additionalSearchControl={{
              activeControl: "origin",
              destination: origin?.id?.code?.code ?? undefined,
            }}
            ref={originRef}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <FlightLocationAutoComplete
            id="destination"
            label=""
            placeholder={t("searchControl.whereTo")}
            onChange={(sug) =>
              sug && setDestination(suggestionToITripTerminus(sug))
            }
            onOpen={onOpen}
            defaultValue={destination && terminusToSuggestion(destination)}
            icon={<img src={icons.airplane} alt="" />}
            additionalSearchControl={{
              activeControl: "destination",
              destination: destination?.id?.code?.code ?? undefined,
            }}
            ref={destinationRef}
          />
        </Grid>
        {destination && origin && (
          <FloatingBox>
            <ActionButton
              message={t("lodging.search.selectDestinationButton")}
              onClick={onClick}
            />
          </FloatingBox>
        )}
      </Grid>
    </>
  );
};
