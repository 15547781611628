import { useExperiment } from "@hopper-b2b/experiments";
import {
  type AirFeatureFlagsContextProps,
  type CommonFeatureFlagsContextProps,
  type LodgingFeatureFlagsContextProps,
  type TripsFeatureFlagsContextProps,
} from "@hopper-b2b/utilities";
import { FEATURE_FLAGS } from "../constants";
import { useMemo } from "react";
import { ExperimentVariant } from "@hopper-b2b/types";

export const useAirEnabled = (): boolean =>
  useExperiment(FEATURE_FLAGS.AIR, ExperimentVariant.Available, true);

export const useLodgingEnabled = (): boolean | undefined =>
  useExperiment(FEATURE_FLAGS.LODGING, ExperimentVariant.Available, true);

export const useWalletEnabled = (): boolean =>
  useExperiment(FEATURE_FLAGS.WALLET);

export const usePricePredictionEnabled = (): boolean =>
  useExperiment(FEATURE_FLAGS.PRICE_PREDICTION);
export const usePriceDropProtectionEnabled = (): boolean =>
  useExperiment(FEATURE_FLAGS.PRICE_DROP_PROTECTION);

export const useCommonFeatureFlags = (): CommonFeatureFlagsContextProps => {
  const enableWallet = useWalletEnabled();

  const featureFlags = useMemo(
    () =>
      ({
        enableCfar: true,
        enableDisruptionProtection: true,
        enableWallet: enableWallet,
      } as CommonFeatureFlagsContextProps),
    [enableWallet]
  );

  return featureFlags;
};

export const useAirFeatureFlags = (): AirFeatureFlagsContextProps => {
  const commonFeatureFlags = useCommonFeatureFlags();
  const enablePricePrediction = usePricePredictionEnabled();
  const enablePriceDropProtection = usePriceDropProtectionEnabled();

  const featureFlags = useMemo(
    () =>
      ({
        ...commonFeatureFlags,
        enableAirShopV4: true,
        enableHFv2: true,
        enablePricePrediction,
        enablePriceDropProtection,
      } as AirFeatureFlagsContextProps),
    [commonFeatureFlags, enablePriceDropProtection, enablePricePrediction]
  );

  return featureFlags;
};

export const useLodgingFeatureFlags = (): LodgingFeatureFlagsContextProps => {
  const commonFeatureFlags = useCommonFeatureFlags();
  const featureFlags = useMemo(
    () =>
      ({
        ...commonFeatureFlags,
        showHorizontalScrollListInMobileMap: true,
      } as LodgingFeatureFlagsContextProps),
    [commonFeatureFlags]
  );

  return featureFlags;
};

export const useTripsFeatureFlags = (): TripsFeatureFlagsContextProps => {
  const commonFeatureFlags = useCommonFeatureFlags();
  const featureFlags = useMemo(
    () =>
      ({
        ...commonFeatureFlags,
        enableAirTripSearch: true,
      } as TripsFeatureFlagsContextProps),
    []
  );

  return featureFlags;
};
