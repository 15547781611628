import { IconComponent, IconName } from "@hopper-b2b/ui";
import {
  Accordion as MUIAccordion,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
import { ReactNode } from "react";
import styles from "./Accordion.module.scss";

export function Accordion({
  title,
  subtitle,
  children,
  defaultExpanded = false,
}: {
  title: string;
  subtitle?: string;
  children: ReactNode;
  defaultExpanded?: boolean;
}) {
  return (
    <MUIAccordion
      square
      variant="elevation"
      elevation={0}
      className={styles.accordion}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        className={styles.accordionSummary}
        expandIcon={
          <IconComponent
            name={IconName.ChevronDown}
            className={styles.accordionIcon}
          />
        }
      >
        <Typography variant="inherit" className={styles.accordionTitle}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="inherit" className={styles.accordionSubtitle}>
            {subtitle}
          </Typography>
        )}
      </AccordionSummary>
      <Box pb="12px">{children}</Box>
    </MUIAccordion>
  );
}
