import { axiosInstance, trackEvent } from "@hopper-b2b/api";
import { NETWORK_CALL_FAILED } from "@hopper-b2b/types";
import { apiConfig } from "@hopper-b2b/utilities";
import { lloydsUserApi } from "@b2bportal/lloyds-user-api";
import { SessionData } from "@b2bportal/lloyds-auth-api";

export const fetchUserInfo = async (): Promise<SessionData> => {
  try {
    const res = await lloydsUserApi(axiosInstance).apiV0UserGetInfoGet();
    return res.data.sessionData;
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      apiConfig
    );
    throw e;
  }
};
