import { combineReducers } from "@reduxjs/toolkit";
import flightPostBookingReducer, {
  FlightPostBookingState,
} from "../features/postbooking/store/slice";
import flightSearchReducer, {
  FlightSearchState,
} from "../features/search/store/slice";
import flightShopReducer, {
  FlightsShopState,
} from "../features/shop/store/slice";

export interface FlightState {
  flights: {
    flightPostBooking: FlightPostBookingState;
    flightSearch: FlightSearchState;
    flightShop: FlightsShopState;
  };
}

export const flightsRootReducer = combineReducers({
  flightPostBooking: flightPostBookingReducer,
  flightShop: flightShopReducer,
  flightSearch: flightSearchReducer,
});
