import { useI18nContext } from "@hopper-b2b/i18n";
import {
  PATH_HOTELS_BOOK_AVAILABILITY,
  PATH_HOTELS_SEARCH,
} from "@hopper-b2b/utilities";
import { IconButton, IconButtonProps } from "@material-ui/core";
import { useCallback } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom-v5-compat";
import { ReactComponent as BackIcon } from "./back-icon.svg";

export type BackButtonProps = IconButtonProps;

export const BackButton = ({
  onClick,
  size = "small",
  color = "inherit",
  ...rest
}: BackButtonProps) => {
  const { t } = useI18nContext();

  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = useCallback(
    (event) => {
      if (location.pathname.includes(PATH_HOTELS_BOOK_AVAILABILITY)) {
        navigate(PATH_HOTELS_SEARCH);
        return;
      }
      onClick ? onClick(event) : navigate(-1);
    },
    [navigate, onClick, location]
  );

  return (
    <IconButton
      onClick={onClickHandler}
      size={size}
      color={color}
      aria-label={t("back")}
      {...rest}
    >
      <BackIcon />
    </IconButton>
  );
};
