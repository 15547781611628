import { useI18nContext } from "@hopper-b2b/i18n";
import { useMemo } from "react";
import { FiatPrice } from "@b2bportal/core-types";

export const useFiatPriceToText = () => {
  const { formatFiatCurrency } = useI18nContext();
  return useMemo(
    () => (fiatPrice: FiatPrice) => {
      return formatFiatCurrency(fiatPrice, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });
    },
    [formatFiatCurrency]
  );
};
