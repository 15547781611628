import { CoreFlightSteps } from "@b2bportal/core-utilities";

export enum TenantFlightSteps {
  FINTECH_CUSTOMIZE = "FINTECH_CUSTOMIZE",
  DISRUPTION = "DISRUPTION",
  CFAR = "CFAR",
}

export const FlightSteps = {
  ...CoreFlightSteps,
  ...TenantFlightSteps,
} as const;

// Type for the values of the combined enum
export type FlightSteps = (typeof FlightSteps)[keyof typeof FlightSteps];
