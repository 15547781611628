import { useI18nContext } from "@hopper-b2b/i18n";
import { RadioOption } from "@hopper-b2b/types";
import { ActionButton, FloatingBox } from "@hopper-b2b/ui";
import { useDeviceTypes, useTenantIcons } from "@hopper-b2b/utilities";
import { RadioInput } from "@lloyds/ui-core";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  type SelectProps,
} from "@material-ui/core";
import { memo, ReactNode, useCallback, useMemo, useState } from "react";
import { LbgModal } from "../LbgModal";
import { TextInputFieldProps } from "../TextInputField";

type SingleSelection = {
  allowMultiSelection?: false;
  selected?: string | RadioOption;
  setOption: (value: string) => void;
  getLabel?: (value: string) => string;
};

type MultipleSelection = {
  allowMultiSelection: true;
  selected?: string[] | RadioOption[];
  setOption: (value: string[]) => void;
  getLabel?: (value: string[]) => string;
};
export type SelectFieldProps = (SingleSelection | MultipleSelection) &
  Pick<SelectProps, "label" | "required" | "onBlur" | "disabled"> &
  Pick<TextInputFieldProps, "helperText"> & {
    options: RadioOption[];
    placeholder?: string;
    prefix?: string;
    startAdornment?: ReactNode;
    hideEndAdornment?: boolean;
  };

export const SelectField = memo(
  ({
    selected,
    options,
    required,
    disabled,
    helperText,
    label,
    placeholder,
    prefix,
    startAdornment,
    hideEndAdornment = false,
    setOption,
    getLabel,
    allowMultiSelection,
  }: SelectFieldProps) => {
    const { matchesMobile } = useDeviceTypes();

    const [touched, setTouched] = useState(false);
    const showError = useMemo(() => touched || selected, [touched, selected]);

    const selectedInternal: RadioOption[] = useMemo(() => {
      if (!selected) {
        return undefined;
      }

      if (allowMultiSelection) {
        if (selected.length === 0) {
          return undefined;
        }

        if (typeof selected[0] === "string") {
          const typedSelected = selected as string[];
          return options.filter(({ value }) => typedSelected.includes(value));
        }
        return selected as RadioOption[];
      } else {
        if (typeof selected === "string") {
          const maybeSelected = options.find(({ value }) => selected === value);

          return maybeSelected ? [maybeSelected] : undefined;
        }
        return [selected] as RadioOption[];
      }
    }, [selected, options, allowMultiSelection]);

    const setOptionsInternal = useCallback(
      (opts: string[]) => {
        if (allowMultiSelection) {
          if (opts.length === 0) return setOption([]);
          setOption(opts);
        } else {
          if (opts.length === 0) return setOption(undefined);
          setOption(opts[0]);
        }
      },
      [allowMultiSelection, setOption]
    );
    const getLabelInternal = useCallback(
      (values: string[]) => {
        if (allowMultiSelection) {
          return getLabel?.(values);
        } else {
          return getLabel?.(values[0]);
        }
      },
      [allowMultiSelection, getLabel]
    );

    return (
      <FormControl
        required={required}
        disabled={disabled}
        error={showError && !!helperText}
      >
        {label && <InputLabel>{label}</InputLabel>}
        {matchesMobile ? (
          <MobileSelectField
            placeholder={placeholder}
            selected={selectedInternal}
            options={options}
            required={required}
            disabled={disabled}
            setTouched={setTouched}
            setOption={setOptionsInternal}
            startAdornment={startAdornment}
            hideEndAdornment={hideEndAdornment}
            getLabel={getLabelInternal}
            allowMultipleSelection={allowMultiSelection}
          />
        ) : (
          <DesktopSelectField
            placeholder={placeholder}
            selected={selectedInternal}
            options={options}
            required={required}
            disabled={disabled}
            prefix={prefix}
            setTouched={setTouched}
            setOption={setOptionsInternal}
            startAdornment={startAdornment}
            hideEndAdornment={hideEndAdornment}
            getLabel={getLabelInternal}
            allowMultipleSelection={allowMultiSelection}
          />
        )}
        {(touched || selected) && !!helperText && (
          <FormHelperText error>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

type SelectFieldInternalProps = Pick<SelectProps, "required" | "disabled"> &
  Pick<TextInputFieldProps, "helperText"> & {
    options: RadioOption[];
    selected?: RadioOption[];
    placeholder?: string;
    setOption: (value: string[]) => void;
    setTouched: (bool: boolean) => void;
    prefix?: string;
    startAdornment?: ReactNode;
    hideEndAdornment?: boolean;
    getLabel?: (value: string[]) => string;
    allowMultipleSelection?: boolean;
  };

export const DesktopSelectField = ({
  placeholder,
  selected = [],
  options,
  required,
  disabled,
  setOption,
  setTouched,
  prefix = "",
  startAdornment,
  hideEndAdornment,
  getLabel,
  allowMultipleSelection,
}: SelectFieldInternalProps) => {
  const { t } = useI18nContext();
  const icons = useTenantIcons();

  const toggleOption = useCallback(
    (option: RadioOption, isSelected: boolean) => {
      if (!allowMultipleSelection) {
        setOption([option.value]);
        return;
      }

      let options: RadioOption[];
      if (isSelected) {
        options = selected.filter((s) => s.value !== option.value);
      } else {
        options = [...selected, option];
      }
      setOption(options.map((opt) => opt.value));
    },
    [selected, allowMultipleSelection, setOption]
  );

  return (
    <Select
      value={
        selected && selected.length > 0 ? selected.map((s) => s.value) : "EMPTY"
      }
      disabled={disabled}
      onBlur={() => setTouched(true)}
      startAdornment={startAdornment}
      endAdornment={
        !hideEndAdornment && (
          <Box
            marginRight={"16px"}
            position="absolute"
            right={0}
            display="flex"
          >
            <img src={icons.chevronDown} alt="" />
          </Box>
        )
      }
      renderValue={(values: string[] | "EMPTY") => {
        if (values === "EMPTY") {
          return placeholder;
        }
        const label =
          getLabel?.(values) ??
          values
            .map((val) => selected.find((s) => s.value === val)?.label)
            .join(", ");

        return (
          <>
            <Typography className="lloyds-select-prefix" variant="inherit">
              {prefix}
            </Typography>
            <Typography className="lloyds-select-label" variant="inherit">
              {label}
            </Typography>
          </>
        );
      }}
    >
      {options?.map((option) => {
        const isOptionSelected = !!selected?.find(
          (s) => s.value === option.value
        );
        return (
          <MenuItem
            key={option.value}
            onClick={() => toggleOption(option, isOptionSelected)}
            value={option.value}
          >
            <Typography>{option.label}</Typography>
          </MenuItem>
        );
      })}
      {!required && (
        <MenuItem onClick={() => setOption([])} value="EMPTY">
          <Typography>{t("none")}</Typography>
        </MenuItem>
      )}
    </Select>
  );
};

export const MobileSelectField = ({
  placeholder,
  selected,
  options,
  required,
  disabled,
  prefix = "",
  setOption,
  setTouched,
  startAdornment,
  hideEndAdornment,
  getLabel,
  allowMultipleSelection,
}: SelectFieldInternalProps) => {
  const icons = useTenantIcons();
  const { t } = useI18nContext();
  const [open, setOpen] = useState(false);
  const [selectedInternal, setSelectedInternal] = useState(selected ?? []);

  const onOpen = useCallback(() => {
    if (!disabled) {
      setOpen(!open);
    }
  }, [disabled, open]);

  const onClose = useCallback(() => {
    setOpen(!open);
    setTouched(true);
  }, [open, setTouched]);

  const onApply = useCallback(() => {
    setOption(selectedInternal.map((s) => s.value));
    onClose();
  }, [setOption, onClose, selectedInternal]);

  const toggleOption = useCallback(
    (option: RadioOption | "NONE", isSelected: boolean) => {
      if (option === "NONE") {
        if (!allowMultipleSelection) {
          setSelectedInternal([]);
          setOption([]);
          onClose();
        } else {
          setSelectedInternal([]);
        }
        return;
      }

      if (!allowMultipleSelection) {
        setSelectedInternal([option]);
        setOption([option.value]);
        onClose();
        return;
      }

      if (isSelected) {
        setSelectedInternal(
          selectedInternal.filter((s) => s.value !== option.value)
        );
      } else {
        setSelectedInternal([...selectedInternal, option]);
      }
    },
    [selectedInternal, allowMultipleSelection, onClose, setOption]
  );

  return (
    <>
      <Select
        value={
          selected && selected.length > 0
            ? selected?.map((s) => s.value)
            : "EMPTY"
        }
        required={required}
        disabled={disabled}
        startAdornment={startAdornment}
        endAdornment={
          !hideEndAdornment && (
            <Box
              marginRight={"14px"}
              position="absolute"
              right={0}
              display="flex"
            >
              <img src={icons.chevronDown} alt="" />
            </Box>
          )
        }
        renderValue={(values: string[] | "EMPTY") => {
          if (values === "EMPTY") {
            return placeholder;
          }
          const label =
            getLabel?.(values) ??
            values
              .map((val) => selected.find((s) => s.value === val)?.label)
              .join(", ");

          return (
            <>
              <Typography className="lloyds-select-prefix" variant="inherit">
                {prefix}
              </Typography>
              <Typography className="lloyds-select-label" variant="inherit">
                {label}
              </Typography>
            </>
          );
        }}
        open={false}
        onClick={onOpen}
      >
        {selectedInternal.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Typography>{option.label}</Typography>
          </MenuItem>
        ))}
      </Select>
      <LbgModal
        open={open}
        onClose={onClose}
        title={placeholder}
        diableContentPadding
      >
        {options?.map((option, idx) => {
          const isOptionSelected = !!selectedInternal?.find(
            (s) => s.value === option.value
          );
          return (
            <Box
              paddingX={"24px"}
              paddingY={"16px"}
              onClick={() => toggleOption(option, isOptionSelected)}
              key={idx}
              display="flex"
              gridGap={12}
              borderBottom={"var(--divider-border)"}
            >
              <RadioInput checked={isOptionSelected} />
              <Typography>{option.label}</Typography>
            </Box>
          );
        })}
        {!required && (
          <Box
            paddingX={"24px"}
            paddingY={"16px"}
            onClick={() => toggleOption("NONE", false)}
            display="flex"
            gridGap={12}
            borderBottom={"var(--divider-border)"}
          >
            <RadioInput checked={selectedInternal.length === 0} />
            <Typography>{t("none")}</Typography>
          </Box>
        )}
        {allowMultipleSelection && (
          <FloatingBox>
            <ActionButton message={t("apply")} onClick={onApply} />
          </FloatingBox>
        )}
      </LbgModal>
    </>
  );
};
