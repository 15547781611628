import { assign, DoneInvokeEvent } from "xstate";
import { ParentState } from "@checkout/types";
import { CommonMachineContext } from "../types";
import { GetVouchersResponseSuccess } from "@b2bportal/lloyds-wallet-api";
import { OfferPaymentType } from "@hopper-b2b/types";

export const walletOfferActions = {
  handleGetWalletOffers: assign(
    (
      context: CommonMachineContext,
      event: DoneInvokeEvent<GetVouchersResponseSuccess>
    ) => {
      const offers = event.data.offers;
      context[ParentState.wallet].fetchedOffers = true;
      if (offers.length > 0) {
        context[ParentState.wallet].offers = offers;
        context[ParentState.wallet].selectedOffer = offers.find(
          (o) => o.payment.value?.paymentType === OfferPaymentType.Offer
        );
      }

      return context;
    }
  ),
};
