import { ChatProvider } from "./ChatProvider";

export enum BottomSheetContentEnum {
  Closed,
  ContactSupport,
  CSATFormResolved,
  CSATFormUnresolved,
}

export interface IChatContext {
  bottomSheetContent: BottomSheetContentEnum;
  chatProvider: ChatProvider;
  closeChatWindow: () => void;
  conversationId: string;
  setBottomSheetContent: (content: BottomSheetContentEnum) => void;
  setConversationId: (id: string | null) => void;
  supportId: string;
}
