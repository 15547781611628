import { Box, Container, Grid, Typography } from "@material-ui/core";
import { PropsWithChildren, ReactNode } from "react";
import styles from "./FullScreen.module.scss";

export type FullScreenProps = {
  icon?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
};

export const FullScreen = ({
  icon,
  title,
  subtitle,
  children,
}: PropsWithChildren<FullScreenProps>) => (
  <Container maxWidth="sm">
    <Box className={styles.fullPage}>
      <Grid container direction="column" className={styles.fullHeight}>
        <Grid item xs={2} />
        <Grid item>
          {(icon || title || subtitle) && (
            <Box textAlign="center" m={4}>
              {icon && <Box my={4}>{icon}</Box>}
              {title && (
                <Typography variant="h1" gutterBottom>
                  {title}
                </Typography>
              )}
              {subtitle && <Typography gutterBottom>{subtitle}</Typography>}
            </Box>
          )}
          <Box textAlign="center" m={3}>
            {children}
          </Box>
        </Grid>
        <Grid item md />
      </Grid>
    </Box>
  </Container>
);
