import {
  CoreFlightsComponent,
  CORE_FLIGHTS_NAME,
  CORE_FINTECH_UI_NAME,
  CoreFintechUiComponents,
  CORE_DISRUPTION_NAME,
  CoreDisruptionComponent,
  CORE_FLIGHTS_CFAR_NAME,
  CoreCfarComponent,
} from "@b2bportal/core-types";

import {
  algomerchTagsStyles,
  fareListFareDetailsStyles,
  filterListStyles,
  filterPriceStyles,
  filtersStyles,
  flightCardFareListStyles,
  flightCardStyles,
  flightFareListItemStyles,
  flightSummaryStyles,
  reviewFarePageStyles,
  reviewPageStyles,
  fintechProductBulletStyles,
  disruptionOfferSectionStyles,
  cfarOfferSectionStyles,
  howItWorksDialogStyles,
  reviewHeaderStyles,
} from "./styles";
import { default as departurePageStyles } from "./components/DeparturePage/DeparturePage.module.scss";
import { default as returnPageStyles } from "./components/ReturnPage/ReturnPage.module.scss";

export const styleOverrides = {
  [CORE_FLIGHTS_NAME]: {
    [CoreFlightsComponent.DeparturePage]: departurePageStyles,
    [CoreFlightsComponent.ReturnPage]: returnPageStyles,
    [CoreFlightsComponent.FlightCard]: flightCardStyles,
    [CoreFlightsComponent.AlgomerchTags]: algomerchTagsStyles,
    [CoreFlightsComponent.FlightSummary]: flightSummaryStyles,
    [CoreFlightsComponent.ReviewFarePage]: reviewFarePageStyles,
    [CoreFlightsComponent.FareRestrictions]: reviewFarePageStyles,
    [CoreFlightsComponent.ReviewPage]: reviewPageStyles,
    [CoreFlightsComponent.ReviewBreakdown]: reviewPageStyles,
    [CoreFlightsComponent.FilterPrice]: filterPriceStyles,
    [CoreFlightsComponent.FareListFareDetails]: fareListFareDetailsStyles,
    [CoreFlightsComponent.FlightCardFareList]: flightCardFareListStyles,
    [CoreFlightsComponent.FlightFareGridItem]: flightFareListItemStyles,
    [CoreFlightsComponent.Filters]: filtersStyles,
    [CoreFlightsComponent.FilterList]: filterListStyles,
    [CoreFlightsComponent.ReviewFlightHeader]: reviewHeaderStyles,
  },
  [CORE_FINTECH_UI_NAME]: {
    [CoreFintechUiComponents.FintechProductBullet]: fintechProductBulletStyles,
  },
  [CORE_DISRUPTION_NAME]: {
    [CoreDisruptionComponent.DisruptionOfferSection]:
      disruptionOfferSectionStyles,
    [CoreDisruptionComponent.HowItWorksDialog]: howItWorksDialogStyles,
  },
  [CORE_FLIGHTS_CFAR_NAME]: {
    [CoreCfarComponent.CfarOfferSection]: cfarOfferSectionStyles,
  },
};
