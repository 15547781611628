import {
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
} from "@checkout/context";
import { CartQuoteEventType, cartQuoteSelectors } from "@checkout/states";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useCallback, useMemo } from "react";
import { TEvent } from "../events";

export const useBaseCartErrorProps = (
  clearError: () => void,
  goBack: () => void
) => {
  const { t } = useI18nContext();
  const send = useCheckoutSend<TEvent>();

  const allowedQuoteRetry = useSelector(cartQuoteSelectors.getAllowQuoteRetry);

  const handleTryAgain = useCallback(() => {
    clearError();
    send(CartQuoteEventType.RETRY_QUOTE);
  }, [clearError, send]);

  const handleGoBack = useCallback(() => {
    clearError();
    goBack();
  }, [clearError, goBack]);

  return useMemo(
    () =>
      allowedQuoteRetry
        ? {
            primaryButtonText: t("checkoutError.tryAgain"),
            primaryOnClick: handleTryAgain,
            secondaryButtonText: t("back"),
            secondaryOnClick: handleGoBack,
          }
        : {
            primaryButtonText: t("back"),
            primaryOnClick: handleGoBack,
          },
    [allowedQuoteRetry, handleGoBack, handleTryAgain, t]
  );
};
