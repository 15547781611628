import { assign } from "xstate";
import { CommonMachineContext } from "../types";
import {
  SetWalletCreditBalanceEvent,
  RemoveWalletCreditBalanceEvent,
} from "../events";
import { ParentState } from "@checkout/types";
import {
  Payment,
  PaymentId,
  PaymentOpaqueValue,
} from "@b2bportal/purchase-api";

export const walletCreditActions = {
  setWalletCredits: assign(
    (ctx: CommonMachineContext, event: SetWalletCreditBalanceEvent) => {
      const credits =
        event.credits ??
        ctx[ParentState.wallet].offers?.find(
          (o) => o.payment.value?.paymentType === "Credit"
        );

      if (credits) {
        ctx[ParentState.credits].walletCredits = credits;
        const walletCreditPayment: PaymentOpaqueValue = {
          type: Payment.Wallet,
          value: {
            offerId: credits.id,
            totalDiscountAmount: credits.amount,
            paymentType: "Credit",
          },
        };

        ctx[ParentState.cartUpdate].addPayments = [
          ...(ctx[ParentState.cartUpdate].addPayments ?? []),
          walletCreditPayment,
        ];
      }

      return ctx;
    }
  ),
  removeWalletCredits: assign(
    (ctx: CommonMachineContext, _event: RemoveWalletCreditBalanceEvent) => {
      const offerAsPayment: PaymentId = {
        id: ctx[ParentState.credits].walletCredits.id,
        payment: Payment.Wallet,
      };
      ctx[ParentState.credits].walletCredits = undefined;

      ctx[ParentState.cartUpdate].removePayments = [
        ...(ctx[ParentState.cartUpdate].removePayments ?? []),
        ...[offerAsPayment],
      ];
      return ctx;
    }
  ),
};
