export const FLIGHT_SHOP_ROUTES = {
  SHOP_ROUTE: "shop",
  DEPARTURE_ROUTE: "departure",
  RETURN_ROUTE: "return",
  REVIEW_ROUTE: "review",
  REVIEW_FARE_ROUTE: "fare",
};

export const FLIGHT_BOOK_ROUTES = {
  BOOK_ROUTE: "book",
  CONFIRMATION: "confirmation",
};

export const FLIGHT_HOME_ROUTE = `/`;

export const FLIGHT_ROUTES = {
  SEARCH_ROUTE: "search",
  SHOP_ROUTE: "shop",
  BOOK_ROUTE: "book",
};
export const FLIGHT_SEARCH_ROUTE = `search`;
export const FLIGHT_SEARCH_CALENDAR_HASH = "#calendar";

export const FLIGHTS_PATH = `/flights`;
export const FLIGHTS_SEARCH_PATH = `${FLIGHTS_PATH}/${FLIGHT_SEARCH_ROUTE}`;

export const FLIGHTS_SHOP_PATH = `${FLIGHTS_PATH}/${FLIGHT_SHOP_ROUTES.SHOP_ROUTE}`;
export const FLIGHTS_SHOP_DEPARTURE_PATH = `${FLIGHTS_SHOP_PATH}/${FLIGHT_SHOP_ROUTES.DEPARTURE_ROUTE}`;
export const FLIGHTS_SHOP_RETURN_PATH = `${FLIGHTS_SHOP_PATH}/${FLIGHT_SHOP_ROUTES.RETURN_ROUTE}`;
export const FLIGHTS_SHOP_REVIEW_PATH = `${FLIGHTS_SHOP_PATH}/${FLIGHT_SHOP_ROUTES.REVIEW_ROUTE}`;
export const FLIGHTS_SHOP_REVIEW_FARE_PATH = `${FLIGHTS_SHOP_REVIEW_PATH}/${FLIGHT_SHOP_ROUTES.REVIEW_FARE_ROUTE}`;

export const FLIGHT_BOOK_PATH = `${FLIGHTS_PATH}/${FLIGHT_BOOK_ROUTES.BOOK_ROUTE}`;
