import { Brand, type SessionData } from "@b2bportal/lloyds-auth-api";
import { useBrandFromUrl } from "@lloyds/utils";
import {
  ISessionContextProps,
  SessionContextProvider,
  SessionStateEnum,
  parsePhoneNumber,
} from "@hopper-b2b/utilities";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { type LloydsSessionInfo, type LloydsUserInfo } from "../../types";

export type UserSessionContext = {
  userSessionInfo: LloydsSessionInfo;
  updateSessionInfo: (response: SessionData) => void;
};

export const UserSessionContext = createContext<UserSessionContext>(undefined);

export const useSessionInfo = (): Partial<LloydsSessionInfo> => {
  const ctx = useContext(UserSessionContext);
  if (!ctx) throw new Error(`must be used within a UserSessionProvider`);
  return ctx?.userSessionInfo;
};

export const useSessionUserInfo = (): LloydsUserInfo => {
  const sessionInfo = useSessionInfo();
  return sessionInfo?.userInfo;
};

export const useUserSessionBrand = (): Brand | undefined => {
  const userInfo = useSessionUserInfo();
  return userInfo?.brand;
};

export type UserSessionProviderProps = {
  defaultBrand?: Brand;
};

export const UserSessionProvider = ({
  defaultBrand,
  children,
}: PropsWithChildren<UserSessionProviderProps>) => {
  const [userSessionInfo, setUserSessionInfo] = useState<LloydsSessionInfo>();

  const brandFromUrl = useBrandFromUrl();

  const brand = defaultBrand ?? brandFromUrl;

  const sessionContext: ISessionContextProps | undefined = useMemo(() => {
    if (userSessionInfo && userSessionInfo.userInfo) {
      const parsedPhone = parsePhoneNumber(userSessionInfo?.userInfo.phone);

      return {
        sessionType: SessionStateEnum.Authenticated,
        isLoggedIn: true,
        email: userSessionInfo?.userInfo.email,
        firstName: userSessionInfo?.userInfo.firstName,
        lastName: userSessionInfo?.userInfo.lastName,
        phoneNumber: parsedPhone.nationalNumber,
        countryCode: parsedPhone.countryCode
          ? `+${parsedPhone.countryCode}`
          : undefined,
      };
    } else {
      return undefined;
    }
  }, [userSessionInfo]);

  const updateSessionInfo = useCallback(
    (response: Partial<SessionData>) => {
      setUserSessionInfo({
        ...userSessionInfo,
        ...response,
        userInfo: {
          ...userSessionInfo?.userInfo,
          ...response,
        },
      });
    },
    [userSessionInfo]
  );

  useEffect(() => {
    if (brand && userSessionInfo?.userInfo?.brand !== brand) {
      if (userSessionInfo?.userInfo?.brand) {
        console.warn("Something seems wrong, brand mismatch found", {
          brand,
          userInfoBrand: userSessionInfo?.userInfo?.brand,
        });
      }
      updateSessionInfo({ brand });
    }
  }, [brand, updateSessionInfo, userSessionInfo?.userInfo?.brand]);

  return (
    <UserSessionContext.Provider value={{ userSessionInfo, updateSessionInfo }}>
      <SessionContextProvider sessionContext={sessionContext}>
        {children}
      </SessionContextProvider>
    </UserSessionContext.Provider>
  );
};
