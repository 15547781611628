import { Button, ButtonProps } from "@material-ui/core";
import { useCallback, useMemo } from "react";

export type TextButtonProps = {
  message: string;
} & ButtonProps;

export const TextButton = ({
  message,
  className,
  color = "primary",
  variant = "text",
  size = "small",
  onClick,
  ...rest
}: TextButtonProps) => {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    },
    [onClick]
  );
  return (
    <Button
      color={color}
      variant={variant}
      size={size}
      className={className}
      onClick={handleClick}
      {...rest}
    >
      {message}
    </Button>
  );
};
