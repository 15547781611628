export const HOTELS_ROUTES = {
  SEARCH_ROUTE: "search",
  AVAILABILITY_ROUTE: "availability",
  SHOP_ROUTE: "shop",
  ROOM_SELECTION_ROUTE: "room-selection",
  BOOK_ROUTE: "book",
};

export const HOTELS_HOME_ROUTE = `/`;

export const HOTELS_SEARCH_CALENDAR_HASH = "#calendar";

export const HOTELS_AVAILABILITY_PARAMS = ":location/:placeId?";
export const HOTELS_PATH = `/hotels`;

export const HOTELS_SEARCH_PATH = `${HOTELS_PATH}/${HOTELS_ROUTES.SEARCH_ROUTE}`;

export const HOTELS_AVAILABILITY_PATH = `${HOTELS_PATH}/${HOTELS_ROUTES.AVAILABILITY_ROUTE}`;

export const HOTELS_SHOP_PATH = `${HOTELS_PATH}/${HOTELS_ROUTES.SHOP_ROUTE}`;
export const HOTELS_SHOP_ROOM_PATH = `${HOTELS_SHOP_PATH}/${HOTELS_ROUTES.ROOM_SELECTION_ROUTE}`;

export const HOTELS_BOOK_PATH = `${HOTELS_PATH}/${HOTELS_ROUTES.BOOK_ROUTE}`;
