import { IImageSet } from "@hopper-b2b/utilities";
import BookingConfirmation from "./client/shared/Bunny-Confirmation-Default.png";
import HomepageBackground from "./client/lloyds/Background.png";
import WalletIllustration from "./client/lloyds/WalletIllustration.jpg";
import { Brand } from "@b2bportal/lloyds-auth-api";

export const lightModeImages = (brand: Brand): IImageSet => ({
  flightBookingConfirmationImage: BookingConfirmation,
  homepageBackground: homepageBackground(brand),
  walletCardImage: WalletIllustration,
});

const homepageBackground = (brand: Brand) => {
  const images: { [key in Brand]: string } = {
    Lloyds: HomepageBackground,
    Halifax: HomepageBackground,
    BankOfScotland: HomepageBackground,
    MBNA: HomepageBackground,
  };

  return images[brand];
};
