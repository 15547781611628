import { useI18nContext } from "@hopper-b2b/i18n";
import { IconComponent, IconName } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Fab, Typography } from "@material-ui/core";
import clsx, { ClassValue } from "clsx";
import { Location } from "history";
import React, { useCallback, useEffect, useState } from "react";
import { matchPath, useHistory } from "react-router";

import { ChatWindow } from "../ChatWindow";
import { ChatProvider, testChatProvider } from "../../types/ChatProvider";
import { resolvePaths } from "../../utils";

import styles from "./ChatFab.module.scss";

export interface IChatFabProps {
  chatProvider?: ChatProvider;
  className?: ClassValue;
  hideChatFabIcon?: string;
  showChatFabIcon?: string;
  /**
   * URL path fragments on which to show the ChatFab.
   * Will be resolved to have a leading slash and no trailing slash
   * per react-router standards
   */
  showPaths?: string[];
}

const ChatFab: React.FC<IChatFabProps> = (props) => {
  const {
    chatProvider = testChatProvider,
    className,
    hideChatFabIcon,
    showChatFabIcon,
    showPaths = [],
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const history = useHistory();
  const { brand, t } = useI18nContext();
  const [activeChatSession, setActiveChatSession] = useState(false);
  const [open, setOpen] = useState(false);
  const [showFab, setShowFab] = useState(false);
  const [resolvedShowPaths] = useState(resolvePaths(showPaths));
  const fabVariant = brand.contactSupport.aiChat?.variant ?? "extended";

  const closeChat = () => {
    setActiveChatSession(false);
    setOpen(false);
  };

  const getHideFabIcon = () => {
    const className = clsx("fab-icon", styles.fabIcon);

    if (hideChatFabIcon) {
      return <img alt="Hide Chat" src={hideChatFabIcon} />;
    }

    return (
      <IconComponent className={className} name={IconName.RoundArrowDown} />
    );
  };

  const getShowFabIcon = () => {
    const className = clsx("fab-icon", styles.fabIcon);

    if (showChatFabIcon) {
      return <img alt="Show Chat" src={showChatFabIcon} />;
    }

    return <IconComponent className={className} name={IconName.SupportChat} />;
  };

  const handleOnClick = () => {
    if (!activeChatSession) {
      setActiveChatSession(true);
    }

    setOpen((prev) => !prev);
  };

  const onLocationChange = useCallback(
    (location?: Location) => {
      const { pathname } = location || window.location;
      const match = matchPath(pathname, resolvedShowPaths);
      const isOnShowPath = Boolean(match);

      if (isOnShowPath) {
        // don't auto-open chat window as user navigates
        setShowFab(true);
      } else {
        setOpen(false);
        setShowFab(false);
      }
    },
    [resolvedShowPaths]
  );

  /**
   * On first load, check if FAB should show or hide based on current path
   */
  useEffect(() => {
    onLocationChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description On init, add an event listener to route changes.
   * When the route changes, check to see if it's in the paths on
   * which to hide the chat button.
   */
  useEffect(() => {
    const unlisten = history.listen(onLocationChange);

    return () => {
      unlisten();
    };
  }, [history, onLocationChange]);

  return (
    <Box
      className={clsx(
        className,
        "chat-fab-container",
        {
          [styles.mobileOpen]: matchesMobile && open,
          [styles.open]: open,
        },
        styles.chatFabContainer
      )}
    >
      {activeChatSession && (
        <ChatWindow
          chatProvider={chatProvider}
          closeChat={closeChat}
          minimizeChat={() => setOpen(false)}
          mobile={matchesMobile}
          open={open}
        />
      )}
      <Fab
        aria-label="help"
        className={clsx(
          "fab-button",
          {
            [styles.showFab]: showFab,
          },
          styles.fabButton
        )}
        classes={{ label: clsx("fab-label", styles.fabLabel) }}
        onClick={handleOnClick}
        variant={fabVariant}
      >
        {open ? getHideFabIcon() : getShowFabIcon()}
        {fabVariant === "extended" && (
          <Typography
            className={clsx("fab-title", styles.fabTitle)}
            variant="body1"
          >
            {t("chat.fabTitle")}
          </Typography>
        )}
      </Fab>
    </Box>
  );
};

export default ChatFab;
