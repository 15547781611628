import { IActionButtonProps as CommonActionButtonProps } from "@hopper-b2b/ui-core";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { Spinner } from "../Spinner";
import styles from "./ActionButton.module.scss";

export type ActionButtonProps = Pick<
  CommonActionButtonProps,
  | "onClick"
  | "defaultStyle"
  | "disabled"
  | "submit"
  | "message"
  | "size"
  | "className"
  | "ariaLabelText"
> & { href?: string; loading?: boolean; fullWidth?: boolean };

export const ActionButton = ({
  message,
  disabled,
  size = "large",
  submit = false,
  defaultStyle = "h4r-primary",
  loading = false,
  fullWidth = true,
  onClick,
  className,
  href,
  ariaLabelText,
}: ActionButtonProps) => {
  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    if (href) {
      const targetElement = document.querySelector(href);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }

    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    }
  };

  return (
    <button
      className={clsx(styles[defaultStyle], styles[size], className, {
        [styles.fullWidth]: fullWidth,
      })}
      onClick={handleClick}
      disabled={disabled}
      aria-label={ariaLabelText}
      {...(submit ? { type: "submit" } : {})}
    >
      <Grid container spacing={2} justifyContent="center">
        {loading && (
          <Grid item>
            <Spinner
              color={
                disabled
                  ? "disabled"
                  : defaultStyle === "h4r-secondary"
                  ? "secondary"
                  : "primary"
              }
            />
          </Grid>
        )}
        <Grid item>{message}</Grid>
      </Grid>
    </button>
  );
};
