import { useMemo } from "react";
import { useLocation } from "react-router-dom-v5-compat";
import { Brand } from "@b2bportal/lloyds-auth-api";
import { getBrandFromUrl } from "../utils/brandUtils";

export const useBrandFromUrl = (): Brand | undefined => {
  const hostname = window?.location?.hostname;

  const { search } = useLocation();
  const brandSearchParam = new URLSearchParams(search).get("brand");

  return useMemo(
    () => getBrandFromUrl(hostname, brandSearchParam),
    [brandSearchParam, hostname]
  );
};
