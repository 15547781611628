import { fetchFlightLocations } from "@hopper-b2b/common-search";
import { Autocomplete, AutocompleteProps } from "@lloyds/ui-connected";
import { IdEnum, Suggestion } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import React from "react";

enum SuggestionTypeEnum {
  City = "city",
  Airport = "airport",
}

interface FlightLocationAutoCompleteProps
  extends Omit<
    AutocompleteProps<Suggestion>,
    "fetch" | "groupBy" | "getOptionLabel" | "sortOptions"
  > {
  defaultValue?: Suggestion;
}

export const FlightLocationAutoComplete = React.forwardRef<
  HTMLInputElement,
  FlightLocationAutoCompleteProps
>(
  (
    {
      id,
      defaultValue,
      label,
      placeholder,
      icon,
      closeIcon,
      additionalSearchControl,
      onChange,
      onOpen,
      className,
    }: FlightLocationAutoCompleteProps,
    forwardedRef
  ) => {
    const { t } = useI18nContext();

    return (
      <>
        <label htmlFor={id} id={`${id}-label`} style={{ display: "none" }}>
          {t(`${id}`)}
        </label>
        <Autocomplete
          id={id}
          key={defaultValue ? "destination-loaded" : "loading-destination"}
          className={className}
          paperClassName={"flight"}
          defaultValue={defaultValue}
          label={label}
          icon={icon}
          placeholder={placeholder}
          closeIcon={closeIcon}
          onOpen={onOpen}
          onChange={onChange}
          getOptionLabel={getOptionLabel}
          sortOptions={sortLocations}
          groupBy={groupPlaceType}
          fetch={(apiConfig, search, callback) =>
            fetchFlightLocations(
              apiConfig,
              search,
              callback,
              additionalSearchControl
            )
          }
          ref={forwardedRef}
        />
      </>
    );
  }
);

const groupPlaceType = (value?: Suggestion): string => {
  const flight = value?.id.Id === IdEnum.Flight ? value.id.code : null;
  const regionType = flight?.regionType;
  return regionType ?? "";
};

const getOptionLabel = (value?: Suggestion) => {
  return value?.label;
};

const sortLocations = (options?: Suggestion[]): Suggestion[] => {
  const groupEntries = {
    [SuggestionTypeEnum.City]: [],
    [SuggestionTypeEnum.Airport]: [],
  };

  options?.forEach((suggestion: Suggestion) => {
    const group: string = groupPlaceType(suggestion);
    groupEntries[group].push(suggestion);
  });

  return [
    ...groupEntries[SuggestionTypeEnum.City],
    ...groupEntries[SuggestionTypeEnum.Airport],
  ];
};
