import { TripCategory } from "@hopper-b2b/types";
import clsx from "clsx";
import dayjs from "dayjs";
import { useMemo } from "react";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "@hopper-b2b/common-search";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { useI18nContext } from "@hopper-b2b/i18n";
import "./CalendarPicker.scss";
import { Box, Typography } from "@material-ui/core";
import { FloatingBox } from "@hopper-b2b/ui";
import { ActionButton } from "@lloyds/ui-core";
import { IMonthBucket } from "@b2bportal/core-types";
import { useHighContrast } from "@lloyds/utils";
import { HighContrastToggle } from "../HighContrastToggle";

type CalendarPickerProps = {
  tripCategory: TripCategory;
  startDate: Date | null;
  endDate: Date | null;
  startDateLabel: string;
  endDateLabel: string;
  warningText?: string;
  startDateIcon?: React.ReactElement;
  endDateIcon?: React.ReactElement;
  priceTags?: string[];
  months?: IMonthBucket[];
  setStartDate: (value: Date | null) => void;
  setEndDate: (value: Date | null) => void;
  onComplete: () => void;
  disableHighContrast?: boolean;
};

export const CalendarPicker = ({
  tripCategory,
  startDate,
  endDate,
  startDateLabel,
  endDateLabel,
  warningText,
  startDateIcon,
  endDateIcon,
  priceTags,
  months,
  setStartDate,
  setEndDate,
  onComplete,
  disableHighContrast = false,
}: CalendarPickerProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { t, brand } = useI18nContext();

  const { isHighContrast } = useHighContrast();

  const focusedMonthIndex = startDate
    ? dayjs(startDate).diff(dayjs(), "month")
    : 0;

  const calendarClassName = useMemo(
    () => (tripCategory === TripCategory.ONE_WAY ? "one-way" : "roundtrip"),
    [tripCategory]
  );

  const readyToSearch = useMemo(() => {
    return tripCategory === TripCategory.ONE_WAY
      ? !!startDate
      : tripCategory === TripCategory.ROUND_TRIP
      ? !!endDate && !!startDate
      : false;
  }, [tripCategory, startDate, endDate]);

  return (
    <Box
      className={clsx(
        "lloyds-calendar-picker",
        !disableHighContrast && isHighContrast && "high-contrast"
      )}
    >
      <MonthAndDatePicker
        className={clsx(calendarClassName)}
        viewType={
          matchesMobile
            ? MonthAndDatePickerType.Column
            : MonthAndDatePickerType.Horizontal
        }
        focusedMonthIndex={focusedMonthIndex}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        overrideScrollOnStart={true}
        tripCategory={tripCategory}
        startDateLabel={startDateLabel}
        endDateLabel={endDateLabel}
        startDateIcon={startDateIcon}
        endDateIcon={endDateIcon}
        priceTags={priceTags}
        currency={brand.currency.code}
        months={months}
        showPriceRangeTags={matchesMobile}
      />
      <FloatingBox>
        {!readyToSearch && (
          <Typography
            align="center"
            variant="subtitle1"
            style={{
              fontWeight: 500,
              paddingBottom: "16px",
            }}
          >
            {warningText}
          </Typography>
        )}
        <Box className={matchesMobile ? "" : "button-container"}>
          {!disableHighContrast && !matchesMobile && <HighContrastToggle />}
          <ActionButton
            disabled={!readyToSearch}
            message={
              matchesMobile
                ? t("completeCalendarButtonMobile")
                : t("completeCalendarButtonDesktop")
            }
            onClick={onComplete}
          />
        </Box>
      </FloatingBox>
    </Box>
  );
};
