export const CORE_FLIGHTS_CFAR_NAME = "core-flights-cfar";

export enum CoreCfarComponent {
  CfarOfferSection = "CfarOfferSection",
  CfarHowItWorksDialog = "CfarHowItWorksDialog",
}

export enum CoreCfarExerciseComponents {
  AirCfarExerciseGenericActionView = "AirCfarExerciseGenericActionView",
  AirCfarExerciseErrorExpired = "AirCfarExerciseErrorExpired",
  AirCfarExerciseErrorExercised = "AirCfarExerciseErrorExercised",
  AirCfarExerciseErrorUnknown = "AirCfarExerciseErrorUnknown",
  AirCfarExerciseErrorVoid = "AirCfarExerciseErrorVoid",
  AirCfarExerciseComplete = "AirCfarExerciseComplete",
  AirCfarExerciseLoadingScreen = "AirCfarExerciseLoadingScreen",
  AirCfarExerciseNavWrapper = "AirCfarExerciseNavWrapper",
  AirCfarExercise = "AirCfarExercise",
}
